import React, { ReactNode } from 'react';
import { prepareStyle, useStyles } from '@ci/styles';

const wrapperStyle = prepareStyle(utils => ({
	display: 'grid',
	gridAutoFlow: 'row',
	gridColumnGap: utils.spacings.md,
	gridRowGap: utils.spacings.md,
	gridTemplateColumns: {
		xs: 'repeat(1, minmax(0, 1fr))',
		md: 'repeat(2, minmax(0, 1fr))',
		lg: 'repeat(3, minmax(0, 1fr))',
	},
	padding: utils.spacings.lg,
}));

interface BlogArticleSectionProps {
	cards: ReactNode[];
}
export const BlogArticleSection = ({ cards }: BlogArticleSectionProps) => {
	const { applyStyle } = useStyles();

	return <div className={applyStyle(wrapperStyle)}>{cards}</div>;
};
