import React, { ReactNode } from 'react';
import { prepareStyle, useStyles } from '@ci/styles';
import { Text } from '@ci/atoms';
import { BackButton } from '@myci/ui-components';
import { Link as IntlLink } from '@myci/gatsby-plugin-intl';

const wrapperStyle = prepareStyle(utils => ({
	margin: `0 auto`,
	paddingTop: utils.spacings.md,
	selectors: {
		[utils.breakpoints.md]: {
			width: '75%',
		},
		[utils.breakpoints.lg]: {
			width: '55%',
		},
	},
}));

const contentWrapperStyle = prepareStyle(utils => ({
	paddingBottom: utils.spacings.xl,
	selectors: {
		'> p': {
			padding: `${utils.spacings.sm} 0`,
		},
		'> h2': {
			fontSize: utils.fontSizes.pdfTitle,
			fontWeight: 'bold',
			margin: `${utils.spacings.md} 0 ${utils.spacings.sm} `,
		},
		'> ol': {
			listStylePosition: 'inside',
			paddingInlineStart: 0,
		},
		'> ol > li': {
			marginBottom: utils.spacings.md,
			marginTop: utils.spacings.xs,
		},
		'> ol > li > ul': {
			listStyleType: 'disc',
			marginTop: utils.spacings.xs,
			paddingInlineStart: utils.spacings.md,
		},
	},
}));

const titleStyle = prepareStyle(utils => ({
	margin: `${utils.spacings.lg} 0`,
	textAlign: 'center !important' as any,
}));

interface BlogArticleDetailProps {
	content: ReactNode;
	title: ReactNode;
}

export const BlogArticleDetail = ({ content, title }: BlogArticleDetailProps) => {
	const { applyStyle } = useStyles();

	return (
		<article className={applyStyle(wrapperStyle)}>
			<IntlLink to="/blog">
				<BackButton />
			</IntlLink>
			<Text variant="viewTitle" tag="h1" customStyle={titleStyle}>
				{title}
			</Text>
			<div className={applyStyle(contentWrapperStyle)}>{content}</div>
		</article>
	);
};
