import React from 'react';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import { Text } from '@ci/atoms';
import { prepareStyle, useStyles } from '@ci/styles';
import { Link as IntlLink } from '@myci/gatsby-plugin-intl';

const cardWrapperStyle = prepareStyle(utils => ({
	backgroundColor: utils.colors.white,
	border: `${utils.borders.widths.sm} solid ${utils.colors.gray300}`,
	borderRadius: utils.borders.radii.badge,
	overflow: 'hidden',
	selectors: {
		'> a, > a:hover': {
			color: 'unset',
			textDecoration: 'none',
		},
	},
}));

const textWrapperStyle = prepareStyle(utils => ({
	padding: utils.spacings.md,
}));

const subtitleStyle = prepareStyle(utils => ({
	marginTop: utils.spacings.sm,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
}));

export interface BlogArticleCardProps {
	slug?: string;
	subtitle?: string;
	thumbnailImageData: IGatsbyImageData;
	title: string;
}
export const BlogArticleCard = ({
	slug,
	title,
	subtitle,
	thumbnailImageData,
}: BlogArticleCardProps) => {
	const { applyStyle } = useStyles();
	const cardImageData = getImage(thumbnailImageData);

	return (
		<div className={applyStyle(cardWrapperStyle)}>
			<IntlLink to={slug}>
				{cardImageData && <GatsbyImage image={cardImageData} alt="" />}
				<div className={applyStyle(textWrapperStyle)}>
					<Text align="center" tag="h4" variant="sectionTitle" color="gray800">
						{title}
					</Text>
					<Text align="center" tag="p" customStyle={subtitleStyle}>
						{subtitle}
					</Text>
				</div>
			</IntlLink>
		</div>
	);
};
