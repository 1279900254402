/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "Jūsu kredītvēsture veidojas no jūsu izpildīto un neizpildīto saistību kopuma."
      })
    }), "\n", _jsx(_components.p, {
      children: "Kredītvēsturi veido arī maksāšanas disciplīnas vēsture, kas sastāv ne tikai no izpildītajām, bet arī no aktīvajām saistībām un no tā, cik akurāti un laicīgi uzņemtās saistības tiek pildītas."
    }), "\n", _jsx(_components.p, {
      children: "Katrs uzņēmums veido savu klientu kredītvēsturi un tā tiek izmantota nākotnē, veidojot no jauna vai atjaunojot esošos sadarbības līgumus."
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Jūsu kredītvēsturei ir liela nozīme."
      }), " Piemēram, pozitīvas kredītvēstures gadījumā kādā no telekomunikāciju uzņēmumiem Jūs varēsiet saņemt kāroto produktu vai pakalpojumu uz nomaksu vai noskatītajam transportlīdzeklim saņemt izdevīgākus aizdevuma nosacījumus kādā no līzinga uzņēmumiem."]
    }), "\n", _jsx(_components.p, {
      children: "Pozitīvas kredītvēstures gadījumā uzņēmums var būt ieinteresēts sniegt Jums vēlamo pakalpojumu ar pēcapmaksu vai pārdot Jūsu izvēlēto preci uz nomaksu. Tāpat pozitīvas kredītvēstures gadījumā uzņēmums var piešķirt Jums labākus nomaksas nosacījumus."
    }), "\n", _jsx(_components.p, {
      children: "Negatīvas kredītvēstures gadījumā uzņēmums var atteikties ar Jums sadarboties vai var sniegt pakalpojumu uz citiem, Jums neizdevīgākiem nosacījumiem, piemēram, preces iegāde uz nomaksu ar lielāku pirmo iemaksu vai augstākiem procenta maksājumiem."
    }), "\n", _jsx(_components.p, {
      children: "Kā uzskatāmu piemēru var minēt situāciju, kad tiek ņemts hipotekārais kredīts, kur aizdevuma summa ir 30 000 EUR un aizdevuma termiņš ir 25 gadi. Uz šādiem nosacījumiem, kredītdevējam paaugstinot procentu likmi tikai par 1%, nāksies pārmaksāt aptuveni 5000 EUR."
    }), "\n", _jsx(_components.p, {
      children: "Aizņemoties atbildīgi, laicīgi veicot visus maksājumus, tai skaitā maksājumus par komunālajiem vai telekomunikāciju pakalpojumiem, kā arī citām uzņemtajām saistībām, Jūs izveidosiet pozitīvu kredītvēsturi, kas ilgtermiņā ļaus iegūt daudz vairāk nekā personām ar negatīvu kredītvēsturi."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "Katrs no mums veido savu kredītvēsturi un tikai mēs paši nesam atbildību par savas kredītvēstures ietekmi uz savu labklājību tagad un nākotnē."
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
