import React, { memo, useEffect } from 'react';
import { compose } from 'ramda';
import { isNilOrEmpty } from 'ramda-extension';
import { MessageDescriptor } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { withMiddleware } from 'redux-syringe';
import { Spinner, Text } from '@ci/atoms';
import { prepareStyle, useStyles } from '@ci/styles';

import {
	fetchArticleTypes,
	getArticleTypes,
	helpMiddleware,
	isFetchingArticleTypes,
} from '@myci/domain-help';
import { HelpDetail } from '@myci/module-help-fo';

const faqSectionStyle = prepareStyle(utils => ({
	paddingTop: utils.spacings.lg,
}));

const faqTitleStyle = prepareStyle(() => ({
	textAlign: 'center !important' as any,
}));

const faqWrapperStyle = prepareStyle(utils => ({
	margin: '0 auto',
	selectors: {
		[utils.breakpoints.lg]: {
			width: '75%',
		},
	},
}));

interface FaqProps {
	title: MessageDescriptor;
}
const PureFaq = ({ title }: FaqProps) => {
	const { applyStyle } = useStyles();
	const dispatch = useDispatch();
	const articleTypes = useSelector(getArticleTypes);
	const isFetching = useSelector(isFetchingArticleTypes);

	useEffect(() => {
		if (isNilOrEmpty(articleTypes)) {
			dispatch(fetchArticleTypes());
		}
	}, [articleTypes]);

	const firstArticleType = articleTypes?.[0]?.type;

	return (
		<div className={applyStyle(faqSectionStyle)}>
			<Text message={title} variant="extraTitle" align="center" customStyle={faqTitleStyle} />
			{isFetching ? (
				<Spinner verticalMargin="md" />
			) : firstArticleType ? (
				<div className={applyStyle(faqWrapperStyle)}>
					<HelpDetail articleType={firstArticleType} isDescriptionVisible={false} />
				</div>
			) : null}
		</div>
	);
};

export const Faq = compose(withMiddleware({ helpMiddleware }, { isGlobal: true }), memo)(PureFaq);
