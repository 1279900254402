import React from 'react';
import { prepareStyle, useStyles } from '@ci/styles';
import { Text } from '@ci/atoms';
import { MessageDescriptor } from 'react-intl';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';

type HeroBackgroundSize = 'small' | 'medium';

const backgroundImageSizes: Record<HeroBackgroundSize, string> = {
	medium: '50dvh',
	small: '40dvh',
};

const heroStyle = prepareStyle(() => ({
	display: 'grid',
}));

interface BackgroundImageStyleProps {
	size: HeroBackgroundSize;
}
const backgroundImageStyle = prepareStyle<BackgroundImageStyleProps>((utils, { size }) => ({
	gridArea: '1/1',
	maxHeight: backgroundImageSizes[size],
}));

const titleWrapperStyle = prepareStyle(utils => ({
	alignItems: 'flex-end',
	display: 'flex',
	gridArea: '1/1',
	padding: utils.spacings.lg,
	position: 'relative',
	selectors: {
		[utils.breakpoints.lg]: {
			padding: utils.spacings.xl,
		},
	},
}));

interface BlogHeroSectionProps {
	backgroundData: IGatsbyImageData;
	size?: HeroBackgroundSize;
	title?: MessageDescriptor;
}
export const BlogHeroSection = ({
	size = 'medium',
	title,
	backgroundData,
}: BlogHeroSectionProps) => {
	const { applyStyle } = useStyles();
	const heroImageData = getImage(backgroundData);

	return (
		<div role="header" className={applyStyle(heroStyle)}>
			{heroImageData && (
				<GatsbyImage
					image={heroImageData}
					alt=""
					role="presentation"
					className={applyStyle(backgroundImageStyle, { size })}
				/>
			)}
			<div className={applyStyle(titleWrapperStyle)}>
				<Text variant="viewTitle" weight="bold" tag="h1" color="white" message={title} />
			</div>
		</div>
	);
};
